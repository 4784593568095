export const auth = state => !!state.token;
export const guest = state => !state.token;
export const broadcast = state => state.broadcast;
export const delimiter = state => state.delimiter;
export const tasks = state => state.tasks;
export const first = state => state.first;
export const last = state => state.last;
export const subscriptions = state => state.subscriptions;

export const page = state => {
  return Math.floor(state.tasks.length / 10) + 1;
};

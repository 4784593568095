<template>
  <div class="navigation">
    <router-link v-if="guest" to="/">Home</router-link>
    <router-link v-if="guest && !signIn" to="/sign-in">Sign In</router-link>
    <router-link v-if="guest && signIn" to="/sign-up">Sign Up</router-link>
    <router-link v-if="auth" to="/dashboard">Dashboard</router-link>
    <router-link v-if="auth" to="/options">Options</router-link>
    <a v-if="auth" v-on:click="out">Sign Out</a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters(['auth', 'guest']),
    signIn() {
      return this.$route.name === 'sign-in';
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions(['unauthorized']),
    out: function() {
      this.unauthorized().then(() => {
        this.$router.push({
          path: '/sign-in',
          query: { redirect: this.$route.fullPath }
        });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>

import * as types from './mutation-types';

export default {
  [types.UPDATE_TOKEN](state, payload) {
    if (payload !== null) {
      localStorage.setItem('token', payload);
    } else {
      localStorage.removeItem('token');
    }
    state.token = !!localStorage.getItem('token');
  },
  [types.UPDATE_BROADCAST](state, payload) {
    if (payload !== null) {
      localStorage.setItem('broadcast', payload);
    } else {
      localStorage.removeItem('broadcast');
    }
    state.broadcast = localStorage.getItem('broadcast');
  },
  [types.UPDATE_DELIMITER](state, payload) {
    state.delimiter = payload;
  },
  [types.UPDATE_TASKS](state, payload) {
    state.tasks = payload;
  },
  [types.APPEND_TASKS](state, payload) {
    let ids = new Set(state.tasks.map(d => d._id));
    state.tasks = [...state.tasks, ...payload.filter(d => !ids.has(d._id))];
  },
  [types.PREPEND_TASKS](state, payload) {
    let ids = new Set(state.tasks.map(d => d._id));
    state.tasks = [...payload.filter(d => !ids.has(d._id)), ...state.tasks];
  },
  [types.UPDATE_TASK](state, payload) {
    const item = state.tasks.find(d => d._id === payload._id);
    if (typeof item !== 'undefined') {
      Object.assign(item, payload);
    }
  },
  [types.REMOVE_TASK](state, payload) {
    let index = state.tasks.findIndex(d => d._id === payload.task_id);
    if (index >= 0) {
      state.tasks.splice(index, 1);
    }
  },
  [types.UPDATE_FIRST](state, payload) {
    state.first = payload;
  },
  [types.UPDATE_LAST](state, payload) {
    state.last = payload;
  },
  [types.UPDATE_SUBSCRIPTIONS](state, payload) {
    state.subscriptions = payload;
  }
};

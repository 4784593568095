import Vue from 'vue';
import Vuex from 'vuex';

import * as getters from './getters';
import mutations from './mutations';
import * as actions from './actions';

Vue.use(Vuex);

let token = !!localStorage.getItem('token');
let broadcast = localStorage.getItem('broadcast');

export default new Vuex.Store({
  state: {
    token: token,
    broadcast: broadcast,
    delimiter: 'comma',
    tasks: [],
    first: false,
    last: false,
    subscriptions: []
  },
  getters,
  mutations,
  actions
});

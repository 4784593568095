import axios from 'axios';

const ai = axios.create({
  baseURL: 'https://api.arkgroup.org/v1'
});

// ai.defaults.headers.post['Content-Type'] = 'application/json';
ai.interceptors.request.use(config => {
  let token = localStorage.getItem('token');
  if (token) {
    config.headers.common['X-Access-Token'] = token;
  }
  return config;
}, undefined);

export default ai;

/* eslint-disable */
const analytics = {
  load: () => {
    /*
     ** Only run on client-side and only in production mode
     */
    if (process.env.NODE_ENV !== 'production') return;

    /*
     ** Include Google Analytics Script
     */
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  },
  ga: (...args) => {
    /*
     ** Only run on client-side and only in production mode
     */
    if (process.env.NODE_ENV !== 'production') return;

    if (typeof ga === "function") {
      ga(...args);
    }
  }
};

analytics.load();
analytics.ga('create', 'UA-60601175-3', 'auto');
analytics.ga('set', 'checkProtocolTask', null); // Disables file protocol checking.
export default analytics;

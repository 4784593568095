<template>
  <div id="app" class="view">
    <div class="header">
      <router-link to="/" class="logo-link">
        <div class="logo">
          <div class="asin-grabber">Asin Grabber</div>
          <div class="light">Light</div>
        </div>
      </router-link>
    </div>
    <navigation></navigation>
    <div class="content">
      <router-view />
    </div>
    <div class="footer">
      <div>
        2021
        <a href="http://arkgroup.org" target="_blank" rel="noreferrer"
          >arkgroup.org</a
        >
        |
        <router-link to="/privacy">privacy</router-link>
        | <a href="mailto:light@arkgroup.org">contact us</a>
      </div>
      <div class="powered">
        powered by
        <a
          href="http://arkgroup.org/asin-grabber/about/"
          target="_blank"
          rel="noreferrer"
          >Asin Grabber</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from './components/Navigation';
import ai from './common/axios-instance';
import { mapActions } from 'vuex';

export default {
  components: {
    Navigation
  },
  created() {
    ai.interceptors.response.use(undefined, error => {
      if (
        error.response.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest
      ) {
        this.unauthorized().then(() => {
          this.$router.push({
            path: '/sign-in',
            query: { redirect: this.$route.fullPath }
          });
        });
      }
      return Promise.reject(error);
    });
  },
  methods: {
    ...mapActions(['unauthorized'])
  }
};
</script>

<style lang="scss">
$font-family: 'Roboto Condensed', 'Segoe UI', Tahoma, sans-serif;
$primary-color-1: #ff7529;
$primary-color-2: #fe5f5e;
$hover-color-1: #d26123;
$hover-color-2: #c64342;
$bg-color: #f3f3f3;
$white: #ffffff;
$black: #000000;
$link-color: #f06214;
$font-color: #808080;

* {
  box-sizing: border-box;
}

html {
  margin: 0;
}

body {
  background-color: #747588;
  margin: 0;

  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 300;
  color: $font-color;
}

.view {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: $white;

  .header {
    display: flex;
    justify-content: center;
  }

  .content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }

  .footer {
    overflow: hidden;
  }
}

.header {
  margin: 16px 8px;
  padding: 16px;

  .logo-link {
    color: $black;
    text-decoration: none;

    .logo {
      text-align: center;

      .asin-grabber {
        height: 24px;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.06em;
        text-indent: 4px;
        color: $black;
      }

      .light {
        height: 64px;
        text-transform: none;
        font-family: 'Playball', 'Roboto Condensed', 'Segoe UI', Tahoma,
          sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 64px;
        line-height: 80px;
        text-align: center;
        color: $black;
      }
    }
  }
}

.content {
  position: relative;
  /*background-color: $white;*/
  margin: 8px 8px 0 8px;
  padding: 8px 8px 16px 8px;
  font-size: 16px;
}

.footer {
  margin: 16px 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: #999999;
  text-align: center;

  a {
    color: #999999;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .powered {
    font-size: 12px;
  }
}

.navigation {
  position: relative;
  height: 32px;
  overflow: hidden;
  background: $link-color;
  text-align: center;

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;

    text-align: right;
    text-decoration-line: none;
    text-transform: uppercase;

    color: $bg-color;
    cursor: pointer;

    + a::before {
      display: inline-block;
      padding-right: 8px;
      padding-left: 8px;
      font-size: 16px;
      line-height: 32px;
      color: #d6d6d6;
      content: '|';
    }

    &:hover {
      text-decoration-line: underline;
    }
  }
}

.heading {
  margin: 13px 0 35px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
  color: $black;

  .sub-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }
}

p {
  margin: 0 0 24px 0;
  line-height: 24px;
  word-wrap: break-word;
}

a {
  color: $link-color;
  cursor: pointer;
  text-decoration-line: underline;

  &:hover {
    text-decoration-line: none;
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 23px;
  border: 0;
  border-top: 1px solid #f3f3f3;
}

ol,
ul {
  margin: 16px 0 16px 0;
  padding: 0 0 0 48px;

  li {
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .view {
    margin: 0 auto;
    width: 768px;
  }

  .content {
    padding: 16px 16px 24px 16px;
  }
}

.button {
  display: inline-block;
  margin: 0;
  padding: 0 16px 0 16px;
  height: 40px;
  border: none;
  background: linear-gradient(
    90deg,
    $primary-color-1 0%,
    $primary-color-2 100%
  );
  font-family: $font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #f9f9f9;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &.wide {
    width: 100%;
  }

  &.submit {
    margin-top: 16px;
  }

  &.outline {
    background: $white;
    border: 1px solid #ff752a;
    color: #ff742a;
  }
}

.button-group {
  display: inline-flex;

  &.wide {
    width: 100%;

    .button {
      flex: 1;
    }
  }
}

.no-margin {
  margin: 0;
}

textarea,
input,
select {
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  background: #f3f3f3;
  border: 1px solid #e0e0e0;
  color: #787878;
  margin: 0;
  padding: 8px;
  line-height: normal;

  &:focus {
    outline: none;
  }
}

::placeholder {
  color: #a7a7a7;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #a7a7a7;
}

::-ms-input-placeholder {
  color: #a7a7a7;
}

.form-control {
  margin: 0;

  label {
    display: block;
    margin: 0 0 8px 0;
    padding: 0;
    font-family: $font-family;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    text-transform: uppercase;
  }

  textarea {
    display: block;
    margin: 0 0 16px 0;
    width: 100%;
    height: 128px;
    min-height: 32px;
    line-height: 16px;
    resize: none;
  }

  input,
  select {
    display: block;
    margin: 0 0 32px 0;
    padding: 0 8px 0 8px;
    width: 100%;
    height: 32px;
    line-height: normal;
  }
}

.notification {
  margin: 0 0 12px 0;
  padding: 8px;
  background: #e4f2ff;
  border: 1px solid #c0e1ff;
  line-height: 16px;
  color: #002f58;

  &.error {
    background: #ffe4e4;
    border: 1px solid #ffc0c0;
    color: #580000;
  }
}

.no-account,
.forgot-password {
  margin-top: 16px;
  text-align: center;
}
</style>

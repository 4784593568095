import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import analytics from '../common/google-analytics';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (privacy.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () =>
      import(/* webpackChunkName: "auth" */ '../views/auth/SignIn'),
    meta: { requiresGuest: true }
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () =>
      import(/* webpackChunkName: "auth" */ '../views/auth/SignUp'),
    meta: { requiresGuest: true }
  },
  {
    path: '/password/reset',
    name: 'forgot-password',
    component: () =>
      import(/* webpackChunkName: "auth" */ '../views/auth/ForgotPassword'),
    meta: { requiresGuest: true }
  },
  {
    path: '/password/reset/:reset',
    name: 'reset-password',
    component: () =>
      import(/* webpackChunkName: "auth" */ '../views/auth/ResetPassword'),
    meta: { requiresGuest: true }
  },
  {
    path: '/payment/complete',
    name: 'payment-complete',
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/payment/Complete')
  },
  {
    path: '/payment/cancelled',
    name: 'payment-cancelled',
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/payment/Cancelled')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    meta: { requiresAuth: true }
  },
  {
    path: '/options',
    name: 'options',
    component: () =>
      import(/* webpackChunkName: "options" */ '../views/options/General'),
    meta: { requiresAuth: true }
  },
  /*{
    path: '/options/billing',
    name: 'billing-options',
    component: () =>
      import(/!* webpackChunkName: "options" *!/ '../views/options/Billing'),
    meta: { requiresAuth: true }
  },*/
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () =>
      import(/* webpackChunkName: "subscribe" */ '../views/subscribe/Subscribe')
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const token = !!localStorage.getItem('token');

  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    return next({
      path: '/sign-in',
      query: { redirect: to.fullPath }
    });
  }

  if (to.matched.some(record => record.meta.requiresGuest) && token) {
    return next({
      path: '/dashboard'
    });
  }

  next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  analytics.ga('set', 'page', to.fullPath);
  analytics.ga('send', 'pageview');
});

export default router;

import * as types from './mutation-types';
import ai from '../common/axios-instance';
//import ei from '../common/echo-instance';

// eslint-disable-next-line no-unused-vars
const eiReconnect = token => {
  //ei.disconnect();
  //ei.options.auth.headers['X-Access-Token'] = token;
  //ei.connect();
};

export const setDelimiter = ({ commit }, payload) => {
  commit(types.UPDATE_DELIMITER, payload);
};

export const unauthorized = ({ commit }) => {
  return new Promise(resolve => {
    commit(types.UPDATE_TOKEN, null);
    commit(types.UPDATE_BROADCAST, null);
    commit(types.UPDATE_DELIMITER, 'comma');
    commit(types.UPDATE_TASKS, []);
    commit(types.UPDATE_FIRST, false);
    commit(types.UPDATE_LAST, false);
    commit(types.UPDATE_SUBSCRIPTIONS, []);
    eiReconnect(null);
    resolve();
  });
};

export const signIn = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    ai.post('/auth/sign-in', payload)
      .then(response => {
        commit(types.UPDATE_TOKEN, response.data.token);
        commit(types.UPDATE_BROADCAST, response.data.broadcast);
        eiReconnect(response.data.token);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const signUp = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    ai.post('/auth/sign-up', payload)
      .then(response => {
        commit(types.UPDATE_TOKEN, response.data.token);
        commit(types.UPDATE_BROADCAST, response.data.broadcast);
        eiReconnect(response.data.token);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// eslint-disable-next-line no-unused-vars
export const forgotPassword = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    ai.post('/auth/forgot-password', payload)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// eslint-disable-next-line no-unused-vars
export const resetPassword = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    ai.post('/auth/reset-password', payload)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// eslint-disable-next-line no-unused-vars
export const changePassword = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    ai.post('/options/change-password', payload)
      .then(response => {
        commit(types.UPDATE_TOKEN, response.data.token);
        eiReconnect(response.data.token);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getTasks = ({ commit, getters }) => {
  return new Promise((resolve, reject) => {
    if (!getters.last) {
      ai.get('/task/list?page=' + getters.page)
        .then(response => {
          commit(types.APPEND_TASKS, response.data.tasks.data);
          commit(types.UPDATE_FIRST, true);
          commit(types.UPDATE_LAST, !response.data.tasks.next_page_url);
          resolve(response);
        })
        .catch(error => {
          commit(types.APPEND_TASKS, []);
          reject(error);
        });
    } else {
      resolve();
    }
  });
};

export const addTask = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    ai.post('/task/start', payload)
      .then(response => {
        commit(types.PREPEND_TASKS, [response.data.task]);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const cancelTask = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    ai.post('/task/cancel', payload)
      .then(response => {
        commit(types.REMOVE_TASK, payload);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const removeTask = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    ai.post('/task/remove', payload)
      .then(response => {
        commit(types.REMOVE_TASK, payload);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const clearTasks = ({ commit }) => {
  return new Promise((resolve, reject) => {
    ai.post('/task/clear')
      .then(response => {
        commit(types.UPDATE_TASKS, []);
        commit(types.UPDATE_LAST, true);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// eslint-disable-next-line no-unused-vars
export const downloadTask = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    ai.post('/task/download', payload)
      .then(response => {
        let blob = new Blob([response.data], {
          type: 'text/csv; encoding=utf8'
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.style = 'display: none;';
        a.href = url;
        a.download = payload.task_id + '.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const setTasks = ({ commit }, payload) => {
  return new Promise(resolve => {
    commit(types.UPDATE_TASKS, payload);
    resolve();
  });
};

export const appendTasks = ({ commit }, payload) => {
  return new Promise(resolve => {
    commit(types.APPEND_TASKS, payload);
    resolve();
  });
};

export const prependTasks = ({ commit }, payload) => {
  return new Promise(resolve => {
    commit(types.PREPEND_TASKS, payload);
    resolve();
  });
};

export const setTask = ({ commit }, payload) => {
  return new Promise(resolve => {
    commit(types.UPDATE_TASK, payload);
    resolve();
  });
};

export const deleteTask = ({ commit }, payload) => {
  return new Promise(resolve => {
    commit(types.REMOVE_TASK, payload);
    resolve();
  });
};

export const getSubscriptions = ({ commit }) => {
  return new Promise((resolve, reject) => {
    ai.get('/subscription/list')
      .then(response => {
        commit(types.UPDATE_SUBSCRIPTIONS, response.data.subscriptions.data);
        resolve(response);
      })
      .catch(error => {
        commit(types.UPDATE_SUBSCRIPTIONS, []);
        reject(error);
      });
  });
};

<template>
  <div class="home">
    <div class="download">
      <div class="information">
        <a
          class="download-link"
          href="https://chrome.google.com/webstore/detail/asin-grabber-light/gdmicanijbiglolpggafeahdicefofbg"
          target="_blank"
          aria-label="Download Chrome Extension"
          rel="noreferrer"
          ><img
            class="browser-icon"
            src="/img/chrome-logo.svg"
            alt="Chrome Extension"
          />
          <div class="link-text">Download Chrome Extension</div></a
        >
        <div>
          Get product ASINs from Amazon pages
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home'
};
</script>

<style scoped lang="scss">
.home {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.download {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .information {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.download-link {
  margin: 0 0 16px 0;

  .browser-icon {
    width: 128px;
  }

  .link-text {
    font-size: 24px;
    font-weight: 400;
  }
}
</style>

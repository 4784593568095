export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_BROADCAST = 'UPDATE_BROADCAST';
export const UPDATE_DELIMITER = 'UPDATE_DELIMITER';
export const UPDATE_TASKS = 'UPDATE_TASKS';
export const APPEND_TASKS = 'APPEND_TASKS';
export const PREPEND_TASKS = 'PREPEND_TASKS';
export const UPDATE_TASK = 'UPDATE_TASK';
export const REMOVE_TASK = 'REMOVE_TASK';
export const UPDATE_FIRST = 'UPDATE_FIRST';
export const UPDATE_LAST = 'UPDATE_LAST';
export const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';
